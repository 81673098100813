import {
  auth,
  functions,
  store,
  usersCollection,
} from "@/plugins/firebaseConfig";
import { UserProfile } from "@/types";
import { PaymentStatus, Role } from "@/types/enums";
import { calculateDeliveryCost, calculateBasketCost } from "@/utils/costs";
import { getPaymentStatus, getReference } from "@/utils/users";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { UserState, RootState } from "../types";

const namespaced: boolean = true;

export const state: UserState = {
  credentials: {} as firebase.default.User,
  profile: {} as UserProfile,
};

export const getters: GetterTree<UserState, RootState> = {
  outstandingBalance(state, getters): number {
    return getters.totalCost - state.profile.totalPaid;
  },
  totalCost(state, getters, rootState, rootGetters): number {
    const items = rootState.shop?.shopItems ?? [];
    return (
      calculateBasketCost(state.profile.basket, items) +
      calculateDeliveryCost(
        state.profile.basket,
        state.profile.deliveryMethod,
        items
      ) +
      rootGetters["walkers/totalCost"]
    );
  },
  isAdmin(state): boolean {
    return state.profile.role === Role.ADMIN;
  },
  isEnhancedStaff(state): boolean {
    return state.profile.role === Role.ENHANCED_STAFF;
  },
  isStaff(state): boolean {
    return [Role.STAFF, Role.ENHANCED_STAFF, Role.ADMIN].includes(
      state.profile.role
    );
  },
  isLeader(state): boolean {
    return state.profile.role === Role.LEADER;
  },
  isParent(state): boolean {
    return state.profile.role === Role.PARENT;
  },
  reference(state): string {
    return getReference(state.profile.id);
  },
  paymentStatus(state, getters): PaymentStatus {
    return getPaymentStatus(state.profile, getters.totalCost);
  },
};

export const actions: ActionTree<UserState, RootState> = {
  fetchProfile(context) {
    context.commit("startLoading", "user/fetchProfile", { root: true });
    return usersCollection
      .doc(context.state.credentials.uid)
      .get()
      .then((res) => {
        const userProfile = res.data();
        if (userProfile) {
          userProfile.id = res.id;
          context.commit("setUserProfile", userProfile);
          context.commit("finishLoading", "user/fetchProfile", { root: true });
        } else if (context.state.profile === null) {
          throw new Error("User profile is null");
        }
      })
      .catch((err) => {
        context.commit("finishLoading", "user/fetchProfile", { root: true });
        context.commit("setErrorMessage", `Cannot fetch user profile: ${err}`, {
          root: true,
        });
      });
  },
  login(context, { email, password }: { email: string; password: string }) {
    context.commit("startLoading", "user/login", { root: true });
    return auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        context.commit("finishLoading", "user/login", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "user/login", { root: true });
        context.commit(
          "setErrorMessage",
          `Login failed: ${err}\nPlease note, if you used the booking system in a previous year, you will need to create a new account for 2024.`,
          {
            root: true,
          }
        );
      });
  },
  register(
    context,
    {
      email,
      password,
      profile,
    }: { email: string; password: string; profile: UserProfile }
  ) {
    context.commit("startLoading", "user/register", { root: true });
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then((credential) => {
        if (credential && credential.user) {
          const id = credential.user.uid;
          profile.id = id;
          profile.createdTimestamp = store.Timestamp.now();
          profile.updatedTimestamp = store.Timestamp.now();
          context.commit("setUserProfile", profile);
          return credential.user
            .updateProfile({
              displayName: `${profile.firstName} ${profile.lastName}`,
            })
            .then(() => {
              return usersCollection.doc(id).set(profile);
            })
            .then(() => {
              const sendEmail = functions.httpsCallable("sendSignUpEmail");
              return sendEmail(profile);
            })
            .catch((err) => {
              context.commit("finishLoading", "user/register", { root: true });
              context.commit("setErrorMessage", `Registration failed: ${err}`, {
                root: true,
              });
            });
        } else {
          throw new Error("No credential returned");
        }
      })
      .then(() => {
        context.dispatch("info/addGroup", profile.group, { root: true });
        context.commit("finishLoading", "user/register", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "user/register", { root: true });
        context.commit("setErrorMessage", `Registration failed: ${err}`, {
          root: true,
        });
      });
  },
  updateUser(context, profile) {
    context.commit("startLoading", "user/updateUser", { root: true });
    console.log(profile);
    profile.updatedTimestamp = store.Timestamp.now();
    return usersCollection
      .doc(profile.id)
      .update(profile)
      .then(() => {
        context.dispatch("info/addGroup", profile.group, { root: true });
      })
      .then(() => {
        context.commit("setUserProfile", profile);
        context.commit("finishLoading", "user/updateUser", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "user/updateUser", { root: true });
        context.commit("setErrorMessage", `Failed to update user: ${err}`, {
          root: true,
        });
      });
  },
  clearData: {
    root: true,
    handler(context) {
      context.commit("setCurrentUser", null);
      context.commit("setUserProfile", {});
    },
  },
};

export const mutations: MutationTree<UserState> = {
  setCurrentUser(state, val: firebase.default.User) {
    state.credentials = val;
  },
  setUserProfile(state, val: UserProfile) {
    state.profile = val;
  },
};

export const user: Module<UserState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
