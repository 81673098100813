
import Vue from "vue";
import Navigation from "./components/Navigation.vue";

interface Time {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export default Vue.extend({
  data() {
    return {
      drawer: false,
      timeToEventEnd: {} as Time,
      errorDialog: false,
    };
  },
  components: { Navigation },
  computed: {
    currentUser() {
      return this.$store.state.user.profile;
    },
    mobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    // eslint-disable-next-line no-undef
    virtualEndDate(): firebase.default.firestore.Timestamp {
      return this.$store.state.info.dates.virtual.end;
    },
    entriesClosed(): boolean {
      const closingDate = this.virtualEndDate.toDate();
      return new Date() > closingDate;
    },
    errorMessage(): string {
      return this.$store.state.errorMessage;
    },
    loading(): boolean {
      return this.$store.state.loading;
    },
  },
  methods: {
    updateDrawerStatus(value: boolean): void {
      this.drawer = value;
    },
    updateRemainingTime(): void {
      const now = new Date();
      const closingDate = this.virtualEndDate ? this.virtualEndDate.seconds : 0;
      const remainingTime = closingDate - now.valueOf() / 1000;
      const days = Math.floor(remainingTime / 86400);
      const hours = Math.floor((remainingTime - days * 86400) / 3600);
      const minutes = Math.floor(
        (remainingTime - days * 86400 - hours * 3600) / 60
      );
      const seconds = Math.floor(
        remainingTime - days * 86400 - hours * 3600 - minutes * 60
      );
      this.timeToEventEnd = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
      setTimeout(() => {
        this.updateRemainingTime(), 1000;
      });
    },
    closeErrorDialog() {
      this.$store.commit("setErrorMessage", "");
    },
  },
  created(): void {
    this.updateRemainingTime();
  },
  watch: {
    errorMessage() {
      if (this.errorMessage !== "") {
        this.errorDialog = true;
      } else {
        this.errorDialog = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch("initialFetch");
  },
});
