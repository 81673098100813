
import Vue from "vue";
import { store } from "../plugins/firebaseConfig";
import { LeaderProfile, StaffProfile, UserProfile, VForm } from "@/types";
import { DeliveryMethod, Role } from "@/types/enums";

export default Vue.extend({
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      registerForm: {
        role: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        group: "",
        phoneNumber: "",
        address: {
          line1: "",
          line2: "",
          town: "",
          county: "",
          postcode: "",
        },
        membershipNumber: "",
        dbsNumber: "",
        hasMedicalDetails: true,
        medicalDetails: "",
        homeContact: {
          firstName: "",
          lastName: "",
          phoneNumber: "",
          address: {
            line1: "",
            line2: "",
            town: "",
            county: "",
            postcode: "",
          },
        },
        subTeam: "",
        checkpointNumber: "",
      },
      passwordForm: {
        email: "",
      },
      performingRequest: false,
      errorMessage: "",
      errorDialog: false,
      resetSentDialog: false,
      groupContactStepperPage: 1,
      role: null as Role | null,
      accountTypes: [
        {
          name: "Parent",
          description:
            "If you just want to take part in the Virtual Challenge, this is the account for you!",
          permissions: ["Virtual Challenge", "Shop"],
          colour: "cyan",
          role: Role.PARENT,
        },
        {
          name: "Leader",
          description:
            "If you're a leader that wants to sign up teams for the in-person Cotswold Marathon, select this account. You will also have access to the Virtual Challenge.",
          permissions: ["Cotswold Marathon 2024", "Virtual Challenge", "Shop"],
          colour: "teal",
          role: Role.LEADER,
        },
        {
          name: "Staff",
          description:
            "If you'll be volunteering with us before or during the event, this is the account type you want to select. You can still do everything a leader can, we'll just ask for a bit more information about yourself",
          permissions: ["Cotswold Marathon 2024", "Virtual Challenge", "Shop"],
          colour: "green",
          role: Role.STAFF,
        },
      ],
    };
  },
  computed: {
    groups(): string[] {
      return this.$store.state.info.groups as string[];
    },
  },
  methods: {
    register() {
      if (!(this.$refs.registerForm2 as VForm).validate()) {
        return;
      }
      if (this.role === null) {
        this.$store.commit(
          "setErrorMessage",
          "No account type has been selected"
        );
        return;
      }
      this.performingRequest = true;
      const basicProfile: UserProfile = {
        firstName: this.registerForm.firstName,
        lastName: this.registerForm.lastName,
        role: this.role,
        totalPaid: 0,
        phoneNumber: this.registerForm.phoneNumber,
        address: this.registerForm.address,

        group: this.registerForm.group,
        email: this.registerForm.email,
        deliveryMethod: DeliveryMethod.POST,
        id: "",
        paymentSent: false,
        basket: [],
        createdTimestamp: new store.Timestamp(0, 0),
        updatedTimestamp: new store.Timestamp(0, 0),
      };

      if (this.role === Role.PARENT) {
        this.registerProfile(basicProfile);
      } else if (this.role === Role.LEADER) {
        const leaderProfile: LeaderProfile = {
          ...basicProfile,
          membershipNumber: this.registerForm.membershipNumber,
          dbsNumber: this.registerForm.dbsNumber,
          role: Role.LEADER,
        };
        this.registerProfile(leaderProfile);
      } else if (this.role === Role.STAFF) {
        const staffProfile: StaffProfile = {
          ...basicProfile,
          membershipNumber: this.registerForm.membershipNumber,
          dbsNumber: this.registerForm.dbsNumber,
          hasMedicalDetails: this.registerForm.hasMedicalDetails,
          medicalDetails: this.registerForm.medicalDetails,
          homeContact: {
            firstName: this.registerForm.homeContact.firstName,
            lastName: this.registerForm.homeContact.lastName,
            phoneNumber: this.registerForm.homeContact.phoneNumber,
            address: {
              line1: this.registerForm.homeContact.address.line1,
              line2: this.registerForm.homeContact.address.line2,
              town: this.registerForm.homeContact.address.town,
              county: this.registerForm.homeContact.address.county,
              postcode: this.registerForm.homeContact.address.postcode,
            },
          },
          subTeam: this.registerForm.subTeam,
          checkpointNumber: this.registerForm.checkpointNumber,
          role: Role.STAFF,
        };
        this.registerProfile(staffProfile);
      }
    },
    registerProfile(profile: UserProfile) {
      this.$store
        .dispatch("user/register", {
          email: this.registerForm.email,
          password: this.registerForm.password,
          profile: profile,
        })
        .then(() => {
          this.$router.push("/?welcome=true");
          this.performingRequest = false;
        })
        .catch((err) => {
          console.error("Registration failed: ", err);
          this.performingRequest = false;
        });
    },
    nextStep(formRef: string) {
      if ((this.$refs[formRef] as VForm).validate()) {
        this.groupContactStepperPage++;
      }
    },
    changeAccountType(role: Role, active: boolean, toggle: Function) {
      this.role = role;
      toggle();
    },
  },
});
