
import { store } from "@/plugins/firebaseConfig";
import { Address, BasketItem, Contact, UserProfile, VForm } from "@/types";
import { DeliveryMethod, Role } from "@/types/enums";
import Vue, { PropType } from "vue";
export default Vue.extend({
  data() {
    return {
      emptyUser: {
        firstName: "",
        lastName: "",
        address: {
          line1: "",
          line2: "",
          town: "",
          county: "",
          postcode: "",
        },
        phoneNumber: "",
        membershipNumber: "",
        dbsNumber: "",
        paymentSent: false,
        deliveryMethod: DeliveryMethod.POST,
        email: "",
        group: "",
        id: "",
        role: Role.PARENT,
        basket: [] as BasketItem[],
        totalPaid: 0,
        createdTimestamp: new store.Timestamp(0, 0),
        updatedTimestamp: new store.Timestamp(0, 0),
        walkerBadgesDelivered: false,
      } as UserProfile,
      role: Role.PARENT,
      emptyHomeContact: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        address: {
          line1: "",
          line2: "",
          town: "",
          county: "",
          postcode: "",
        },
      } as Contact,
    };
  },
  computed: {
    profile(): UserProfile {
      let profile = this.userProp ? this.userProp : this.emptyUser;
      if (!profile.address) {
        profile.address = {} as Address;
      }
      return profile;
    },
    homeContact(): Contact {
      return this.userProp.homeContact ?? this.emptyHomeContact;
    },
    groups(): string[] {
      return this.$store.state.info.groups as string[];
    },
    form(): VForm {
      return this.$refs.userForm as VForm;
    },
    membershipNumberRequired(): boolean {
      return this.role !== Role.PARENT;
    },
    dbsNumberRequired(): boolean {
      return this.role !== Role.PARENT && this.role !== Role.LEADER;
    },
    isStaff(): boolean {
      return [Role.STAFF, Role.ENHANCED_STAFF, Role.ADMIN].includes(this.role);
    },
    roles(): { text: string; value: Role }[] {
      const availableRoles = [
        {
          text: "Parent",
          value: Role.PARENT,
        },
        {
          text: "Leader",
          value: Role.LEADER,
        },
        {
          text: "Staff",
          value: Role.STAFF,
        },
      ];

      if ([Role.ENHANCED_STAFF, Role.ADMIN].includes(this.profile.role)) {
        availableRoles.push({
          text: "Enhanced Staff",
          value: Role.ENHANCED_STAFF,
        });
      }

      if (this.profile.role === Role.ADMIN) {
        availableRoles.push({
          text: "Admin",
          value: Role.ADMIN,
        });
      }

      return availableRoles;
    },
  },
  methods: {
    async saveChanges() {
      if (!this.form.validate() || !this.profile.id) {
        return;
      }

      this.profile.role = this.role;

      if (this.isStaff) {
        this.profile.homeContact = this.homeContact;
      }

      try {
        if (this.profile.id === this.$store.state.user.profile.id) {
          await this.$store.dispatch("user/updateUser", this.profile);
        }
        if (this.$store.getters["user/isAdmin"]) {
          await this.$store.dispatch("admin/updateUser", this.profile);
        }
        this.$emit("exit", true);
      } catch (err) {
        console.log(err);
      }
    },
  },
  watch: {
    membershipNumberRequired() {
      (this.$refs.userForm as HTMLFormElement).resetValidation();
    },
    dbsNumberRequired() {
      (this.$refs.userForm as HTMLFormElement).resetValidation();
    },
    userProp(newUserProp, oldUserProp) {
      if (newUserProp.id !== oldUserProp.id) {
        console.log({ role: this.userProp.role });
        this.role = this.userProp.role;
      }
    },
  },
  props: {
    userProp: { type: Object as PropType<UserProfile> },
  },
  mounted() {
    console.log({ role: this.userProp.role });
    this.role = this.userProp.role;
  },
});
