
import { BasketItem, UserProfile } from "@/types";
import Vue from "vue";
import UserForm from "./UserForm.vue";
import { createInvoice } from "@/utils/invoice";
import { DeliveryMethod, Role } from "@/types/enums";
import { store } from "@/plugins/firebaseConfig";

export default Vue.extend({
  data() {
    return {
      emptyUser: {
        firstName: "",
        lastName: "",
        address: {
          line1: "",
          line2: "",
          town: "",
          county: "",
          postcode: "",
        },
        phoneNumber: "",
        membershipNumber: "",
        dbsNumber: "",
        paymentSent: false,
        deliveryMethod: DeliveryMethod.POST,
        email: "",
        group: "",
        id: "",
        role: Role.PARENT,
        basket: [] as BasketItem[],
        totalPaid: 0,
        createdTimestamp: new store.Timestamp(0, 0),
        updatedTimestamp: new store.Timestamp(0, 0),
      } as UserProfile,
      paymentSentDialog: false,
    };
  },
  computed: {
    user(): UserProfile {
      return this.$store.state.user.profile;
    },
    profile(): UserProfile {
      return this.user ? this.user : this.emptyUser;
    },
    reference(): string {
      return this.user && this.user.id
        ? this.$store.getters["user/reference"]
        : "";
    },
    outstandingBalance(): number {
      return this.$store.getters["user/outstandingBalance"];
    },
  },
  methods: {
    paymentSent() {
      this.profile.paymentSent = true;
      this.$store
        .dispatch("user/updateUser", this.profile)
        .then(() => {
          this.paymentSentDialog = true;
        })
        .catch((err) => {
          throw err;
        });
    },
    downloadInvoice() {
      const walkers = this.$store.getters["walkers/getWalkers"](this.user.id);
      createInvoice(
        this.user,
        walkers,
        this.$store.getters["user/totalCost"],
        this.$store.state.shop.shopItems
      );
    },
  },
  components: {
    UserForm,
  },
});
