
/* eslint-disable no-undef */
import Vue, { PropType } from "vue";
import moment from "moment";
import { UserProfile, VForm, Walker } from "@/types";
import { Gender } from "@/types/enums";
import { getRouteColour } from "@/utils/colours";

export default Vue.extend({
  data() {
    return {
      emptyWalker: {
        id: "",
        route: "",
        firstName: "",
        lastName: "",
        gender: Gender.MIXED,
        // dob: String(new Date().toISOString()).substr(0, 10),
        dob: "",
        group: "",
        outOfCounty: false,
        hasMedicalDetails: true,
        medicalDetails: "",
        mobileNumber: "",
        homeContact: {
          firstName: "",
          lastName: "",
          phoneNumber: "",
          address: {
            line1: "",
            line2: "",
            town: "",
            county: "",
            postcode: "",
          },
        },
        email: "",
        dbsNumber: "",
        verified: false,
        retired: false,
        age: 0,
        membershipNumber: "",
        walkerNumber: 0,
        lastCheckpoint: "",
        startTime: "",
        finishTime: "",
        totalTime: "",
        contact: "",
        cost: 0,
      } as Walker,
      options: {
        gender: ["Male", "Female", "Other", "Prefer not to say"],
        route: ["Bronze", "Silver", "Gold"],
      },
      dobMenu: false,
      minBronzeDob: new Date(2013, 1, 3),
      minSilverDob: new Date(2011, 1, 3),
      minGoldDob: new Date(2010, 1, 3),
      adultDob: new Date(2006, 1, 3),
      menuDate: "",
      fieldDate: "",
      contact: "",
      useAlias: false,
      hasMedicalDetails: true,
    };
  },
  props: {
    walkerProp: {
      type: Object as PropType<Walker>,
    },
    confirmation: {
      type: Boolean,
    },
  },
  computed: {
    walkerInfo(): Walker {
      if (this.walkerProp && this.walkerProp.id) {
        return this.walkerProp;
      } else {
        const walker = this.emptyWalker;
        walker.group = this.$store.state.user.profile.group;
        return walker;
      }
    },
    buttonText(): string {
      if (this.confirmation) {
        return "Confirm Walker Details";
      } else if (this.walkerProp) {
        return "Save Walker";
      } else {
        return "Add Walker";
      }
    },
    routeColour(): string {
      return getRouteColour(this.walkerInfo.route);
    },
    minDob(): Date {
      let minDob = new Date();
      switch (this.walkerInfo.route) {
        case "Gold":
          minDob = this.minGoldDob;
          break;
        case "Silver":
          minDob = this.minSilverDob;
          break;
        case "Bronze":
          minDob = this.minBronzeDob;
      }
      return minDob;
    },
    defaultRule(): ((v: any) => boolean | string)[] {
      if (this.confirmation) {
        return [(v) => !!v || "Required"];
      } else {
        return [];
      }
    },
    medicalDetailsRule(): ((v: any) => boolean | string)[] {
      if (this.confirmation) {
        return [
          (v) =>
            !!v ||
            !this.walkerInfo.hasMedicalDetails ||
            "Please provide some information about the walker's medical conditions, or declare that they have no medical conditions using the button above.",
        ];
      } else {
        return [];
      }
    },
    isAdult(): boolean {
      return new Date(this.walkerInfo.dob) <= this.adultDob;
    },
    entryFee(): number {
      const now = new Date();
      if (now < this.earlyBirdEndsDate) {
        return 15;
      } else {
        return 16;
      }
    },
    groups(): String[] {
      return this.$store.state.info.groups;
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    walkerDob(): string {
      return this.walkerInfo.dob;
    },
    eventDate(): string {
      const date = (
        this.$store.state.info.dates.main
          .event as firebase.default.firestore.Timestamp
      ).toDate();
      return date.toISOString().substr(0, 10);
    },
    users(): UserProfile[] {
      return this.$store.state.admin.users;
    },
    isAdmin(): boolean {
      return this.$store.getters["user/isAdmin"];
    },
    user(): UserProfile {
      return this.$store.state.user.profile;
    },
    earlyBirdEndsDate(): Date {
      const date = (
        this.$store.state.info.dates.main
          .earlyBirdEnds as firebase.default.firestore.Timestamp
      ).toDate();
      return date;
    },
  },
  watch: {
    menuDate() {
      this.fieldDate = this.menuDate
        ? moment(this.menuDate, "yyyy-MM-DD").format("DD/MM/yyyy")
        : "";
      this.walkerInfo.dob = this.menuDate;
    },
    walkerDob() {
      if (this.walkerInfo.dob !== this.menuDate) {
        this.menuDate = this.walkerInfo.dob;
      }
    },
    walkerInfo() {
      this.contact = this.user.id;
    },
  },
  methods: {
    validateForm() {
      if (this.form.validate()) {
        this.addWalker();
      }
    },
    addWalker() {
      this.calculateAge();

      if (this.confirmation) {
        this.$emit("onSavedChanges", this.walkerInfo);
        this.$emit("exit", true);
        return;
      }

      this.walkerInfo.email = this.walkerInfo.email.toLowerCase();
      if (this.useAlias) {
        this.walkerInfo.contact = this.contact;
      }

      if (this.walkerInfo.id) {
        this.$store
          .dispatch("walkers/updateWalker", this.walkerInfo)
          .then(() => {
            this.form.resetValidation();
            this.$emit("exit", true);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        this.walkerInfo.cost = this.entryFee;
        this.$store
          .dispatch("walkers/createWalker", this.walkerInfo)
          .then(() => {
            this.clearData();
            this.$emit("exit", true);
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    clearData() {
      this.form.resetValidation();
      this.emptyWalker = {
        id: "",
        route: "",
        firstName: "",
        lastName: "",
        gender: Gender.MIXED,
        // dob: String(new Date().toISOString()).substr(0, 10),
        dob: "",
        group: "",
        outOfCounty: this.walkerInfo.outOfCounty,
        hasMedicalDetails: true,
        medicalDetails: "",
        mobileNumber: "",
        homeContact: {
          firstName: "",
          lastName: "",
          phoneNumber: "",
          address: {
            line1: "",
            line2: "",
            town: "",
            county: "",
            postcode: "",
          },
        },
        email: "",
        dbsNumber: "",
        verified: false,
        retired: false,
        age: 0,
        membershipNumber: "",
        walkerNumber: 0,
        lastCheckpoint: "",
        startTime: "",
        finishTime: "",
        totalTime: "",
        contact: "",
        cost: 0,
      } as Walker;
    },
    calculateAge() {
      this.walkerInfo.age = moment(this.eventDate).diff(
        moment(this.walkerInfo.dob),
        "years"
      );
    },
    setMenuDate() {
      this.menuDate = this.fieldDate
        ? moment(this.fieldDate, "DD/MM/yyyy").format("yyyy-MM-DD")
        : "";
    },
    dateIsBefore(d1: string, d2: string): boolean {
      if (/\d{2}\/\d{2}\/\d{4}/.test(d1)) {
        const date = d1.split("/");
        return moment(`${date[2]}-${date[1]}-${date[0]}`).isBefore(d2);
      } else {
        return false;
      }
    },
    getReadableDate(date: Date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  created() {
    this.menuDate = this.walkerInfo.dob;
    this.contact = this.user.id;
  },
});
