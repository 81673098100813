import { VirtualWalker, Walker } from "@/types";
import { VirtualRoute } from "@/types/enums";
import { jsPDF } from "jspdf";
import { getDistanceFromStart } from "./route";
import fonts from "@/assets/fonts/fonts";

const logo = require("../assets/BOOT600.png");
const scoutLogo = require("../assets/ScoutLogo.png");
const girlguidingLogo = require("../assets/GirlguidingLogo.jpg");

export function createMainCertificate(walker: Walker) {
  const doc = new jsPDF({ orientation: "l", unit: "pt", format: "a5" });

  generateMainCertificate(doc, walker);

  doc.save(`Certificate ${walker.walkerNumber}.pdf`);
}

export function createMultipleMainCertificates(walkers: Walker[]) {
  const doc = new jsPDF({ orientation: "l", unit: "pt", format: "a5" });

  for (let i = 0; i < walkers.length; i++) {
    generateMainCertificate(doc, walkers[i]);
    doc.addPage("a5", "l");
  }
  doc.deletePage(walkers.length + 1);

  doc.save(
    `${walkers[0].retired ? "Retired" : walkers[0].route} Certificates.pdf`
  );
}

function generateMainCertificate(doc: jsPDF, walker: Walker) {
  doc.setFont(fonts.NUNITO_SANS_REGULAR, "normal");

  doc.rect(5, 5, 585, 410);
  doc.rect(8, 8, 579, 404);

  generateHeader(doc);
  generateInformation(doc, walker);
  generateFooter(doc);
}

function generateHeader(doc: jsPDF) {
  doc.addImage(logo, "PNG", 257.5, 20, 80, 64);
  doc.setFillColor("#444444");
  doc.setFontSize(45);
  doc.setFont(fonts.NUNITO_SANS_BLACK, "normal");
  doc.text("Cotswold Marathon", 297.5, 125, { align: "center" });
  doc.setFont(fonts.NUNITO_SANS_BOLD, "normal");
  doc.setFontSize(20);
  doc.text("3rd - 4th February 2024", 297.5, 150, {
    align: "center",
  });
}

function generateInformation(doc: jsPDF, walker: Walker) {
  doc.setFillColor("#444444");
  doc.setFontSize(20);
  doc.setFont(fonts.NUNITO_SANS_REGULAR, "normal");
  doc.text("This is to certify that", 297.5, 195, { align: "center" });

  doc.setFontSize(30);
  doc.setFont(fonts.NUNITO_SANS_EXTRA_BOLD, "normal");
  doc.text(`${walker.firstName} ${walker.lastName}`, 297.5, 228, {
    align: "center",
  });

  doc.setFontSize(16);
  doc.setFont(fonts.NUNITO_SANS_REGULAR, "normal");
  doc.text(`of ${walker.group}`, 297.5, 255, { align: "center" });

  doc.setFontSize(20);
  if (walker.totalTime) {
    doc.text(`Completed the ${walker.route} Cotswold Marathon`, 297.5, 285, {
      align: "center",
    });

    const time = walker.totalTime.split(":");
    const hours = time[0].charAt(0) === "0" ? time[0][1] : time[0];

    doc.text(`In a time of ${hours} hours ${time[1]} minutes`, 297.5, 315, {
      align: "center",
    });
  } else {
    doc.text(`Entered the 2024 Cotswold Marathon`, 297.5, 285, {
      align: "center",
    });

    const miles = getDistanceFromStart(walker.lastCheckpoint);

    doc.text(`and completed ${miles} miles`, 297.5, 315, { align: "center" });
  }
}

function generateFooter(doc: jsPDF) {
  doc.addImage(scoutLogo, "PNG", 15, 325, 90, 85);
  doc.addImage(girlguidingLogo, "JPG", 500, 335, 70, 70);
}

export function createVirtualCertificate(walker: VirtualWalker) {
  const doc = new jsPDF({ orientation: "l", unit: "pt", format: "a5" });

  generateVirtualCertificate(doc, walker);

  doc.save(`Virtual Certificate - ${walker.firstName} ${walker.lastName}.pdf`);
}

export function createMultipleVirtualCertificates(walkers: VirtualWalker[]) {
  const doc = new jsPDF({ orientation: "l", unit: "pt", format: "a5" });

  for (let i = 0; i < walkers.length; i++) {
    generateVirtualCertificate(doc, walkers[i]);
    doc.addPage("a5", "l");
  }
  doc.deletePage(walkers.length + 1);

  doc.save(`Virtual Certificates.pdf`);
}

function generateVirtualCertificate(doc: jsPDF, walker: VirtualWalker) {
  doc.setFont(fonts.NUNITO_SANS_REGULAR, "normal");

  doc.rect(5, 5, 585, 410);
  doc.rect(8, 8, 579, 404);

  generateVirtualHeader(doc);
  generateVirtualInformation(doc, walker);
  generateFooter(doc);
}

function generateVirtualHeader(doc: jsPDF) {
  doc.addImage(logo, "PNG", 257.5, 20, 80, 64);
  doc.setFillColor("#444444");
  doc.setFontSize(45);
  doc.setFont(fonts.NUNITO_SANS_BLACK, "normal");
  doc.text("Cotswold Marathon", 297.5, 125, { align: "center" });
  doc.setFontSize(30);
  doc.text("Virtual Event", 297.5, 155, { align: "center" });
  doc.setFont(fonts.NUNITO_SANS_BOLD, "normal");
  doc.setFontSize(20);
  doc.text("January 2024", 297.5, 177, {
    align: "center",
  });
}

function generateVirtualInformation(doc: jsPDF, walker: VirtualWalker) {
  doc.setFillColor("#444444");
  doc.setFontSize(20);
  doc.setFont(fonts.NUNITO_SANS_REGULAR, "normal");
  doc.text("This is to certify that", 297.5, 210, { align: "center" });

  doc.setFontSize(30);
  doc.setFont(fonts.NUNITO_SANS_EXTRA_BOLD, "normal");
  doc.text(`${walker.firstName} ${walker.lastName}`, 297.5, 245, {
    align: "center",
  });

  const distance = walker.activityLog
    ? walker.activityLog.reduce((total, log) => (total += log.distance), 0)
    : 0;
  const route =
    distance >= 50
      ? VirtualRoute.PLATINUM
      : distance >= 30
      ? VirtualRoute.GOLD
      : distance >= 20
      ? VirtualRoute.SILVER
      : distance >= 10
      ? VirtualRoute.BRONZE
      : "";

  doc.setFontSize(20);
  doc.setFont(fonts.NUNITO_SANS_REGULAR, "normal");
  if (route) {
    doc.text(`Completed the ${route} Cotswold Marathon`, 297.5, 280, {
      align: "center",
    });
    doc.text(
      `With a distance of ${distance.toFixed(2)} Marathon miles`,
      297.5,
      310,
      { align: "center" }
    );
  } else {
    doc.text(`Entered the Virtual Cotswold Marathon`, 297.5, 280, {
      align: "center",
    });
    doc.text(
      `And completed ${distance.toFixed(2)} Marathon miles`,
      297.5,
      310,
      { align: "center" }
    );
  }
}
